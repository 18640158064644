import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import { PageIntro } from '../components/Layout/PageIntro/PageIntro';
import ModuleArea from '../components/ModularContent/ModuleArea';

const OtherPagesTemplate = ({
  data: {
    datoCmsOtherPage: { title, modularContent, id, metaTags },
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  return (
    <PageWrapper pageData={pageContext} metaTags={metaTags}>
      <PageIntro title={title} breadcrumbs={breadcrumbs} />
      {modularContent.length > 0 && <ModuleArea blocks={modularContent} />}
    </PageWrapper>
  );
};

export default OtherPagesTemplate;

export const query = graphql`
  query OtherPagesQuery($locale: String!, $id: String!) {
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
    datoCmsOtherPage(locale: { eq: $locale }, originalId: { eq: $id }) {
      locale
      title
      metaTags {
        title
        description
        image {
          url
        }
      }
      id: originalId
      modularContent {
        ... on DatoCmsContentBlock {
          ...Content
        }
        ... on DatoCmsSliderStandardWidthBlock {
          ...SliderStandardWidth
        }
        ... on DatoCmsImageBlock {
          ...Image
        }
        ... on DatoCmsContentWithImageBlock {
          ...ContentWithImage
        }
        ... on DatoCmsSliderFullWidthBlock {
          ...SliderFullWidth
        }
        ... on DatoCmsFeatureBlock {
          ...Feature
        }
        ... on DatoCmsDocumentListBlock {
          ...DocumentList
        }
      }
    }
  }
`;
